import { STUDENT_DETAIL_PENDING, STUDENT_DETAIL_SUCCESS, STUDENT_DETAIL_FAIL } from "../Actions/Types";

const initialState = {
    getStudentDetail: [],
};
const student_detail_reducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case STUDENT_DETAIL_SUCCESS:
            return {
                ...state,
                getStudentDetail: payload.getStudentDetail,
            };
        case STUDENT_DETAIL_PENDING:
            return {
                ...state,
                getStudentDetail: payload.getStudentDetail,
            };
        case STUDENT_DETAIL_FAIL:
            return {
                ...state,
                getStudentDetail: initialState.getStudentDetail,
            };

        default:
            return state;
    }
};
export default student_detail_reducer;