import React from "react";
import { useNavigate } from "react-router-dom";


export const StudentSessionCard = ({ sessionData }) => {
    const completionPercentage = (sessionData.lessons_completed / sessionData.total_lessons) * 100;
    return (
        <div className="h-[200px] w-full p-[20px] flex justify-center border border-neutral-3 items-center rounded-[9px] lg:w-[315px]">
            <div className="flex flex-col justify-between lg:w-[266px] h-[152px]">
                <div className="flex flex-col gap-[16px]">
                    <h5 className="Heading-16 text-font_color-primary">{"current Lesson Name"}</h5>
                    <div className="flex items-center gap-[8px]">
                        <img
                            className="h-[24px] w-[24px] rounded-full"
                            alt="teacher"
                            src={sessionData.teacher.image_url}
                        />
                        <p className="subtitle3 text-font_color-body lg:subtitle2">{sessionData.teacher.teacher_name}</p>
                    </div>
                </div>
                <div className="flex flex-col gap-[12px]">
                    <div className="flex gap-[16px] items-center">
                        <div className="flex-grow bg-neutral-4 rounded-full h-2">
                            <div className="bg-primary-1 h-2 rounded-full" style={{ width: `${completionPercentage}%` }}></div>
                        </div>
                        <p className="body-2 text-right text-font_color-primary lg:body-1">{sessionData.lessons_completed}/<span className="text-font_color-body">{sessionData.total_lessons} Completed </span> </p>
                    </div>
                    <p className="body-2 text-left text-font_color-body lg:body-1">Overall Score: <span className="text-font_color-primary">9</span> out of 10</p>
                </div>
            </div>
        </div>
    )
}