import React, { useState, useEffect, useRef } from "react";
import "./Avatar.css";
import { BASE_URL } from "../../Constants";
import { WEB_SOCKET } from "../../Constants";
import { useParams } from "react-router-dom";
import { TypeAnimation } from "react-type-animation";
import { useDispatch, useSelector } from "react-redux";
import { startLessonDelivery, endLessonDelivery } from "../../Redux/Reducers/lessonSlice";
import { endModal } from "../../Redux/Reducers/LeaveAvatarSlice";
import { useNavigate } from "react-router-dom";
import { TailSpin } from "react-loader-spinner";
import Lottie from "lottie-react";


const AvatarScreen = ({ lessonData, teacherData, session_lesson_id, lessonContent, chatHistory}) => {
  const navigate = useNavigate();
  const progress = lessonContent.progress;
  const [showFlashCard, setShowFlashCard] = useState(false);
  const [flashCardContent, setFlashCardContent] = useState("");
  const [isPaused, setIsPaused] = useState(false);
  const [contentDelivered, setContentDelivered] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(lessonContent.is_completed === false? lessonContent.consumed_length : 0);
  const [currentOrder, setCurrentOrder] = useState(lessonContent.lesson.order);
  const [inputValue, setInputValue] = useState("");
  const [speechInput, setSpeechInput] = useState("");
  const [isListening, setIsListening] = useState(false);
  const [isListeningCont, setIsListeningCont] = useState(true);
  const { session_id, teacher_id, lesson_id } = useParams();
  const [messages, setMessages] = useState([]);
  const CONTENT_TYPE_QUESTION = "question";
  const [flashIndex, setflashIndex] = useState(0);
  const CONTENT_TYPE_TEXT = "text";
  const CONTENT_TYPE_FLASH_CARD = "flashcard";
  const TERMINAL_MESSAGE = "RESPONSE_IS_ENDED";
  const lessonContentTextData = lessonData.content;
  const userData = JSON.parse(localStorage.getItem("user"));
  const token = userData.tokens.AccessToken;
  const messageContainerRef = useRef(null);
  const isListeningContRef = useRef(true);
  let counter =0;
 const [showStart, setshowStart] = useState(false);
 const isDelivering = useSelector((state) => state.lesson.isDelivering);
 const dispatch = useDispatch();
 const Modal = useSelector((state) => state.AvatarModal.showModal);
 const Path = useSelector((state) => state.AvatarModal.path);
 const [showkeyboard, setshowkeyboard] = useState(false);
 const socket = new WebSocket(`wss://${WEB_SOCKET}progress-update/`);
 const [isTalking, setisTalking] = useState(false);
 const [FlashPath, setFlashPath] = useState("");
const AI_URL = "https://dev.knnect.ai/ai/";
let lessonPaused = false;
const [paused, setpaused] = useState(false);
const [hidePause, sethidePause] = useState(false);
const [loadRecap, setloadRecap] = useState(false);
const [animationData, setAnimationData] = useState(null);



useEffect(() => {
  fetch('/voice_waves.json')
      .then((response) => response.json())
      .then((data) => setAnimationData(data))
      .catch((error) => console.error('Error loading Lottie animation:', error));
}, []);
const sendProgressUpdate = (isCompleted) => {
  if(lessonContent.is_completed === false)
  {
    socket.onopen = () =>{
      const progressData = {
        session_lesson_id: session_lesson_id, 
        is_completed: isCompleted,
        consumed_length: currentIndex, 
        session_id: session_id
      };
  
      socket.send(JSON.stringify(progressData));
      console.log('Progress update sent:', progressData);
  }
  }
  
 
};

const handleRetake = () => {
  setCurrentIndex(0);
  executeLesson();
}
const closeModal = () => {
  setIsListeningCont(true);
    isListeningContRef.current = true;
  handlePause();
  continuousSpeech();
  dispatch(endModal());
 
};

const navigateTo = () => {
  dispatch(endLessonDelivery());
  setIsListeningCont(false);
  isListeningContRef.current = false;
  dispatch(endModal());
  window.KlleonChat.close();
  navigate(`/app/${Path}`);
  window.location.reload();
 
};

useEffect(() => {
  if (Modal) {
    setIsListeningCont(false);
        isListeningContRef.current = false;
        handlePause();
  }
}, [Modal]);

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://sdk.klleon.io/klleon-chat/0.9.0/klleon_chat_sdk.js";
    script.async = true;
    script.onload = () => {
      const sdkOption = { sdk_key: "APP-WJwvCzoYkWZbqqBrrNjM" };
      const avatarOption = {
        avatar_id: teacherData.avatar_id,
        voice_code: "en_us",
        subtitle_code: "en_us",
        voice_tts_speech_speed: 0.8,
        radius: 24,
      };
      if (window.KlleonChat) {
        window.KlleonChat.init(sdkOption);
        window.KlleonChat.showStreaming(avatarOption);
        window.KlleonChat.onChatEvent((eventData) => {
          const sdkResponse = JSON.parse(eventData);
          
          if (sdkResponse.chat_type === TERMINAL_MESSAGE) {
            setContentDelivered(true);
          }
          
          if(sdkResponse.chat_type === "ACTIVATE_VOICE")
          {
            
            setTimeout(() => {
              setshowStart(true);
            setshowkeyboard(true);
            chatHistory.forEach((message) => { 
              const text = message.content;
              const type = message.type;
        
              if(type === 2)
                {
                  const type=4;
                    const newMessage = { text, type };
                setMessages((prevMessages) => [...prevMessages, newMessage]);
            
                }
                else{
                  const type = 3;
                  const newMessage = { text, type };
                setMessages((prevMessages) => [...prevMessages, newMessage]);
                }
            });
            }, 2000);
          }

          
          
        });
        
      } else {
        console.error("KlleonChat is not defined after script load.");
      }
      
    };
    script.onerror = (error) => {
      console.error("Error loading Klleon Chat SDK script:", error);
    };
    document.body.appendChild(script);
    socket.onopen = () => {
      console.log('WebSocket connection established with the server.');
      
    };
    
    socket.onmessage = (event) => {
      console.log('Message from server:', event.data);
    };
    
    socket.onerror = (error) => {
      console.error('WebSocket error:', error);
    };
    
    socket.onclose = () => {
      console.log('WebSocket connection closed.');
    };

    return () => {
      if (document.body.contains(script)) {
        document.body.removeChild(script);
      }
    };
  }, []);

  useEffect(() => {
    if (!isPaused && contentDelivered) {
      callNextLine();
    }
  }, [isPaused, contentDelivered]);

 

  useEffect(() => {
    if (messageContainerRef.current) {
      messageContainerRef.current.scrollTop =
        messageContainerRef.current.scrollHeight;
    }
  }, [messages]);

  const handleWordTyped = () => {
    if (messageContainerRef.current) {
      messageContainerRef.current.scrollTop =
        messageContainerRef.current.scrollHeight;
    }
  }

  useEffect(() => {
    let intervalId;
    if (!paused) {
      intervalId = setInterval(handleWordTyped, 3000);
    }
  
    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [paused]);
  const handleLessonChange = () => {
    
    const nextLesson = lessonContent.all_lessons.find(
      (lesson) => lesson.order === currentOrder + 1
    );
  
    const nextLessonId = nextLesson ? nextLesson._id : null;
  
    if (nextLessonId) {
      dispatch(endModal());
      window.KlleonChat.close();
      setIsListeningCont(false);
      setIsListening(false);
      setContentDelivered(false);
      setTimeout(() => {
        navigate(`/app/my-learning/enrolled-course/my-lesson/${session_id}/${teacher_id}/${nextLessonId}`);
        window.location.reload();
      }, 1000);
      
    
    } else {
      console.log('No next lesson found.');
    }
  };
  


  const executeLesson = () => {
    dispatch(startLessonDelivery());
    setshowStart(false);
    continuousSpeech();
    if (!isPaused && currentIndex < lessonContentTextData.length) {
      if (window.KlleonChat) {

        if (lessonContentTextData[currentIndex].type === CONTENT_TYPE_FLASH_CARD) {
          setFlashCardContent(lessonContentTextData[currentIndex].path);
          setFlashPath(lessonContentTextData[currentIndex].path);
          setflashIndex(currentIndex);
          setTimeout(() => {
            setShowFlashCard(true);
          }, 1000);
          setContentDelivered(false);
          setTimeout(() => {
            setContentDelivered(true);
          }, 1000);
        }
        
        else
        {
          addMessage(lessonContentTextData[currentIndex].content, 2);
          window.KlleonChat.echo(lessonContentTextData[currentIndex].content);
          setCurrentIndex(currentIndex + 1);
         setContentDelivered(false);
        }
          
          
        
      } else {
        console.error("KlleonChat is not defined.");
      }
    } else {
      console.log("All messages have been processed or lesson is paused.");
    }
  };

  const addMessage = (text, type) => {
    if(type === 2)
    {
      setTimeout(() => {
        const newMessage = { text, type };
    setMessages((prevMessages) => [...prevMessages, newMessage]);
      }, 3000);
    }
    else{
      const newMessage = { text, type };
    setMessages((prevMessages) => [...prevMessages, newMessage]);
    }

    
  };

  const deleteRecentMessage = () => {
    if (messages.length > 0) {
      setMessages(messages.slice(0, -1));
    }
  }

  const callNextLine = () => {
    if (isPaused) {
      console.log("Lesson is paused, exiting callNextLine");
      return;
    }

    if (contentDelivered) {
      if (currentIndex < lessonContentTextData.length) {
        const currentLessonObject = lessonContentTextData[currentIndex];
        setCurrentIndex(currentIndex + 1);
        if (
          currentLessonObject.type === CONTENT_TYPE_TEXT  
        ) {
          setContentDelivered(false);
          addMessage(lessonContentTextData[currentIndex].content, 2);
          window.KlleonChat.echo(currentLessonObject.content);
          if (flashIndex + 2 === currentIndex) {
            setShowFlashCard(false);
            setflashIndex(0);
          }
        }
        else if (currentLessonObject.type === CONTENT_TYPE_QUESTION )
        {
          sethidePause(true);
          setTimeout(() => {
            let eventListener = true;
          setContentDelivered(false);
          addMessage(lessonContentTextData[currentIndex].content, 2);
          window.KlleonChat.echo(currentLessonObject.content);
          if (flashIndex + 2 === currentIndex) {
            setShowFlashCard(false);
            setflashIndex(0);
          }

          setContentDelivered(true);
          window.KlleonChat.onChatEvent((eventData) => {
            const sdkResponse = JSON.parse(eventData);
            if (sdkResponse.chat_type === TERMINAL_MESSAGE && eventListener === true) {
              eventListener = false;
              setTimeout(() => {
                if(lessonPaused === false)
                {
                  setisTalking(true);
                  handleAvatarQuestion(currentLessonObject.content);
                }
                
              }, 2000);
              
            }
          });
          }, 2000);
          
        }
         else if (currentLessonObject.type === CONTENT_TYPE_FLASH_CARD) {
          setFlashPath(currentLessonObject.path);
          setFlashCardContent(currentLessonObject.path);
          setflashIndex(currentIndex);
          setTimeout(() => {
            setShowFlashCard(true);
          }, 1000);
          setContentDelivered(false);
          setTimeout(() => {
            setContentDelivered(true);
          }, 1000);
        }
        sendProgressUpdate(false);

      }
      else{
        sendProgressUpdate(true);
        handleLessonChange();

      }
    }
  };

  const showFC = (flashCardContent) => {
    return (
      <div className="absolute top-[8%] left-[5%] w-[160px] h-[208px]">
        <img
          src={flashCardContent}
          alt="Flash Card"
          className={`flex items-center justify-center transition-all ${
            showFlashCard
              ? "duration-500 opacity-100 scale-100"
              : "duration-[5000ms] opacity-0 scale-50"
          }`}
        />
      </div>
    );
  };

  const handlePause = () => {
    if (!isPaused) {
      setIsPaused(true);
      window.KlleonChat.stopEcho();
      setIsListeningCont(false);
      console.log("Pausing lesson");
      
    } else  {
      console.log("Resuming lesson");
      setIsPaused(false);
      setContentDelivered(true);
      setIsListeningCont(true);
      
      

    }
  };
  
  const pauseLesson = () =>
  {
    deleteRecentMessage();
    if(currentIndex !== 0)
    {
      setCurrentIndex(currentIndex - 1);
    }
    setpaused(true);
    lessonPaused = true;
    setIsPaused(true);
      window.KlleonChat.stopEcho();
      setIsListening(false);
      console.log("Pausing lesson");
  }

  const resumeLesson = () =>
    {
      setpaused(false);
      lessonPaused = false;
      setIsPaused(false);
      setContentDelivered(true);
      
    }

  const handleUserInput = async () => {
    sethidePause(true);
    setisTalking(true);
    setIsListeningCont(false);
        isListeningContRef.current = false;
        handlePause();
    if (inputValue.trim() !== "") {
      addMessage(inputValue, 1);
      setInputValue("");

      const requestPayload = {
        query: inputValue,
        query_type_id: "66d1e50ddc0dd3da60afc0df",
        consumed_length_query: "100",
        lesson_id: lesson_id,
        session_id: session_id,
        teacher_id: teacher_id,
      };

      try {
        const response = await fetch(`${BASE_URL}handle-interrupt/`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + { token },
          },
          body: JSON.stringify(requestPayload),
        });

        if (response.ok) {
          let eventListener = true;
          const data = await response.json();
          const binaryData = new TextEncoder().encode(data.result.streaming_text);
          const decoder = new TextDecoder("utf-8");
          const correctedText = decoder.decode(binaryData);
          console.log("Corrected API response:", correctedText);
          window.KlleonChat.echo(correctedText);
          addMessage(correctedText, 2);
          window.KlleonChat.onChatEvent((eventData) => {
            const sdkResponse = JSON.parse(eventData);
            if (sdkResponse.chat_type === TERMINAL_MESSAGE && eventListener === true) {
              // window.KlleonChat.echo("Continuing with the lecture");
              // addMessage("Continuing with the lecture", 2);
              eventListener = false;
              setTimeout(() => {
                console.log("Resuming lesson");
                setIsPaused(false);
                setIsListeningCont(true);
                continuousSpeech();
                setContentDelivered(true);
                setisTalking(false);
                sethidePause(false);
              }, 3000);
            }
          });

          
           
        } else {
          console.error("Failed to fetch the API response");
        }
      } catch (error) {
        console.error("Error while making API call:", error);
      }
    }
  };

  const handleSpeechRecognition = async () => {
    setisTalking(true);
    setInputValue("");
    const SpeechRecognition =
        window.SpeechRecognition || window.webkitSpeechRecognition;
    if (!SpeechRecognition) {
        console.error("Speech recognition not supported in this browser.");
        return;
    }

    const recognition = new SpeechRecognition();
    recognition.lang = "en-US";
    recognition.interimResults = true;
    recognition.maxAlternatives = 1;
    recognition.continuous = true; // Allows continuous speech recognition

    let completeSpeech = "";
    let backendCall = false;

    recognition.onstart = () => {
        console.log("Speech recognition started. Please speak...");
        setIsListening(true);
    };

    recognition.onresult = (event) => {
        let interimTranscript = "";

        for (let i = event.resultIndex; i < event.results.length; i++) {
            const transcript = event.results[i][0].transcript;
            if (event.results[i].isFinal) {
                completeSpeech += transcript + " "; 
            } else {
                interimTranscript += transcript; 
            }
        }

        setSpeechInput(interimTranscript);
        setInputValue(interimTranscript);
    };

    recognition.onerror = (event) => {
        console.error("Speech recognition error:", event.error);

        if (event.error === "no-speech") {
            backendCall = true;
            if (counter < 3) {
                window.KlleonChat.echo("I could not hear you, please speak again");
                addMessage("I could not hear you, please speak again", 2);
                setTimeout(() => {
                    counter++;
                    recognition.start();
                }, 6000);
            } else if (counter >= 3) {
                window.KlleonChat.echo("Continuing with the lecture");
                addMessage("Continuing with the lecture", 2);
                setTimeout(() => {
                    sethidePause(false);
                    console.log("Resuming lesson");
                    setIsPaused(false);
                    setIsListeningCont(true);
                    continuousSpeech();
                    setContentDelivered(true);
                    setisTalking(false);
                }, 5000);
            }
        }
        if (event.error === "not-allowed") {
            backendCall = true;
            window.KlleonChat.echo("Continuing with the lecture");
            addMessage("Continuing with the lecture", 2);
            setTimeout(() => {
                sethidePause(false);
                console.log("Resuming lesson");
                setIsPaused(false);
                setIsListeningCont(true);
                continuousSpeech();
                setContentDelivered(true);
                setisTalking(false);
            }, 6000);
        }
    };

    recognition.onspeechend = () => {
        console.log("Speech has stopped being detected.");
        setIsListening(false);
    };

    recognition.onend = async () => {
        console.log("Speech recognition ended.");
        setIsListening(false);

        // Only make API call when we have gathered speech
        if (completeSpeech.trim() !== "") {
            backendCall = true;
            addMessage(completeSpeech, 1);
            setInputValue("");

            const requestPayload = {
                query: completeSpeech,
                query_type_id: "66d1e50ddc0dd3da60afc0df",
                consumed_length_query: "100",
                lesson_id: lesson_id,
                session_id: session_id,
                teacher_id: teacher_id,
            };

            try {
                const response = await fetch(`${BASE_URL}handle-interrupt/`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: "Bearer " + token,
                    },
                    body: JSON.stringify(requestPayload),
                });

                if (response.ok) {
                    let eventListener = true;
                    const data = await response.json();
                    const binaryData = new TextEncoder().encode(data.result.streaming_text);
                    const decoder = new TextDecoder("utf-8");
                    const correctedText = decoder.decode(binaryData);
                    console.log("Corrected API response:", correctedText);
                    window.KlleonChat.echo(correctedText);
                    addMessage(correctedText, 2);
                    window.KlleonChat.onChatEvent((eventData) => {
                        const sdkResponse = JSON.parse(eventData);
                        if (sdkResponse.chat_type === TERMINAL_MESSAGE && eventListener === true) {
                            eventListener = false;
                            setTimeout(() => {
                                sethidePause(false);
                                console.log("Resuming lesson");
                                setIsPaused(false);
                                setIsListeningCont(true);
                                continuousSpeech();
                                setContentDelivered(true);
                                setisTalking(false);
                            }, 3000);
                        }
                    });
                } else {
                    console.error("Failed to fetch the API response");
                }
            } catch (error) {
                console.error("Error while making API call:", error);
            }
        } else {
            console.log("No valid speech input detected.");
        }
    };

    recognition.start();
};

  // const handleFurther = async () => {
  //   const SpeechRecognition =
  //     window.SpeechRecognition || window.webkitSpeechRecognition;
  //   if (!SpeechRecognition) {
  //     console.error("Speech recognition not supported in this browser.");
  //     return;
  //   }

  //   const recognition = new SpeechRecognition();
  //   recognition.lang = "en-US";
  //   recognition.interimResults = true;
  //   recognition.maxAlternatives = 1;
  //   let backendCall = false; 
    

  //   recognition.onstart = () => {
  //     console.log("Speech recognition started. Please speak...");
  //     setIsListening(true);
  //   };

  //   recognition.onresult = async (event) => {
  //     const isFinal = event.results[event.results.length - 1].isFinal;
  //     const speechResult = event.results[0][0].transcript;

  //     setSpeechInput(speechResult);
  //     console.log(speechResult);
  //     setInputValue(speechResult);

      

  //     if (isFinal && speechResult.trim() !== "") {
  //       backendCall = true;
  //       recognition.stop();
  //       addMessage(speechResult, 1);
  //       setInputValue("");

  //       const requestPayload = {
  //         query: speechResult,
  //         query_type_id: "66d1e50ddc0dd3da60afc0df",
  //         consumed_length_query: "100",
  //         lesson_id: lesson_id,
  //         session_id: session_id,
  //         teacher_id: teacher_id,
          
  //       };

  //       try {
  //         const response = await fetch(`${BASE_URL}handle-interrupt/`, {
  //           method: "POST",
  //           headers: {
  //             "Content-Type": "application/json",
  //             Authorization: "Bearer " + token,
  //           },
  //           body: JSON.stringify(requestPayload),
  //         });
      
  //         if (response.ok) {
  //           let eventListener = true;
  //           const data = await response.json();
  //           console.log("API response", data);
  //           window.KlleonChat.echo(data.result.streaming_text);
  //           addMessage(data.result.streaming_text, 2);
  //           window.KlleonChat.onChatEvent((eventData) => {
  //             const sdkResponse = JSON.parse(eventData);
  //             if (sdkResponse.chat_type === TERMINAL_MESSAGE && eventListener === true) {
  //               window.KlleonChat.echo("anything else that i can help you with?");
  //               addMessage("anything else that i can help you with?", 2);
  //               eventListener = false;
  //               setTimeout(() => {
  //                 handleFurther();
  //               }, 5000);
  //             }
  //           });


  //         } else {
  //           console.error("Failed to fetch the API response");
  //         }
  //       } catch (error) {
  //         console.error("Error while making API call:", error);
  //       }
  //     }

  //   };

  //   recognition.onerror = (event) => {
  //     console.error("Speech recognition error:", event.error);
  //     if(event.error === 'no-speech')
  //     {
  //       backendCall = true;
  //       console.log("Resuming lesson");
  //       window.KlleonChat.echo("resuming the lecture");
  //           addMessage("resuming the lecture", 2);
  //           setTimeout(() => {
  //             setIsPaused(false);
  //           setIsListeningCont(true);
  //           continuousSpeech();
  //           setContentDelivered(true);
  //           }, 5000);
              
  //     }
  //   };
  //   recognition.onspeechend = () => {
  //     console.log("Speech has stopped being detected.");
  //   };
  //   recognition.onend = () => {
  //     setIsListening(false);
  //   console.log("Speech recognition ended.");
  //   if(!backendCall)
  //     {
  //       window.KlleonChat.echo("I could not understand, please speak again");
  //         addMessage("I could not understand, please speak again", 2);
  //         setTimeout(() => {
  //           recognition.start();
  //         }, 6000);    
  //     }
  //   };
  //   recognition.start();
  // };


  const continuousSpeech = async () => {
    const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
    if (!SpeechRecognition) {
      console.error("Speech recognition not supported in this browser.");
      return;
    }
  
    const recognition = new SpeechRecognition();
    recognition.continuous = false;
    recognition.interimResults = false;
  
    recognition.onstart = () => {
      console.log("Speech recognition started...");
      setIsListeningCont(true);
      isListeningContRef.current = true; 
    };
  
    recognition.onresult = (event) => {
      const transcript = event.results[event.resultIndex][0].transcript.trim();
      console.log("User said: ", transcript);
  
      if (transcript.toLowerCase().includes("hi teacher")) {
        console.log("Detected 'Hi Teacher'. Stopping speech recognition.");
        setIsListeningCont(false);
        isListeningContRef.current = false;
        recognition.stop();
        handlePause();
        window.KlleonChat.echo("Yes, how can I help you?");
        addMessage("Yes, how can I help you?", 2);
        counter = 0;
        sethidePause(true);
        setTimeout(() => {
          handleSpeechRecognition();
        }, 6000);
        
      }
    };
  
    recognition.onerror = (event) => {
      console.error("Speech recognition error:", event.error);
    };
  
    recognition.onend = () => {
     
      if (isListeningContRef.current ) {
        console.log("Restarting speech recognition...");
        recognition.start();
      }
    };
  
    recognition.start();
  };
  const handleSpeech = () =>
  {
    setIsListeningCont(false);
    isListeningContRef.current = false;
    sethidePause(true);
    handlePause();
    handleSpeechRecognition();

  }

  const handleAvatarQuestion = async (question) => {
    
      setInputValue("");
      setisTalking(true);
      setIsListeningCont(false);
      isListeningContRef.current = false;
      handlePause();
      const SpeechRecognition =
        window.SpeechRecognition || window.webkitSpeechRecognition;
      if (!SpeechRecognition) {
        console.error("Speech recognition not supported in this browser.");
        return;
      }

      const recognition = new SpeechRecognition();
      recognition.lang = "en-US";
      recognition.interimResults = true;
      recognition.maxAlternatives = 1;

      let backendCall = false;

      recognition.onstart = () => {
        
          console.log("Speech recognition started. Please speak...");
          setIsListening(true);
          backendCall = false;
        
        
        
      };

      recognition.onresult = async (event) => {
        
          const isFinal = event.results[event.results.length - 1].isFinal;
        const speechResult = event.results[0][0].transcript;

        setSpeechInput(speechResult);
        console.log(speechResult);
        setInputValue(speechResult);

        if (isFinal && speechResult.trim() !== "" ) {
          backendCall = true;
          addMessage(speechResult, 1);
          setInputValue("");

          const requestPayload = {
            query: question,
            answer: speechResult,
            query_type_id: "66d1e51adc0dd3da60afc0e0",
            consumed_length_query: "100",
            lesson_id: lesson_id,
            session_id: session_id,
            teacher_id: teacher_id,
            flashcard: FlashPath,
          };

          try {
            const response = await fetch(`${BASE_URL}handle-interrupt/`, {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
              },
              body: JSON.stringify(requestPayload),
            });

            if (response.ok ) {
              let eventListener = true;
              const data = await response.json();
              const binaryData = new TextEncoder().encode(data.result.streaming_text);
              const decoder = new TextDecoder("utf-8");
              const correctedText = decoder.decode(binaryData);
              console.log("Corrected API response:", correctedText);
              window.KlleonChat.echo(correctedText);
              addMessage(correctedText, 2);
              window.KlleonChat.onChatEvent((eventData) => {
                const sdkResponse = JSON.parse(eventData);
                if (
                  sdkResponse.chat_type === TERMINAL_MESSAGE &&
                  eventListener === true
                ) {
                  eventListener = false;
                  setTimeout(() => {
                    sethidePause(false);
                    console.log("Resuming lesson");
                    setIsPaused(false);
                    setIsListeningCont(true);
                    continuousSpeech();
                    setContentDelivered(true);
                    setisTalking(false);
                  }, 3000);
                }
              });
            } else {
              console.error("Failed to fetch the API response");
            }
          } catch (error) {
            console.error("Error while making API call:", error);
          }
        }
        
        
      };

      recognition.onerror = (event) => {
        
          console.error("Speech recognition error:", event.error);

        if (event.error === "no-speech" ) {
          backendCall = true;
          if (counter < 3) {
            window.KlleonChat.echo("I could not hear you, please speak again");
            addMessage("I could not hear you, please speak again", 2);
            setTimeout(() => {
              counter++;
              recognition.start();
            }, 6000);
          } else if (counter >= 3) {
            // window.KlleonChat.echo("Continuing with the lecture");
            // addMessage("Continuing with the lecture", 2);
            setTimeout(() => {
              sethidePause(false);
              console.log("Resuming lesson");
              setIsPaused(false);
              setIsListeningCont(true);
              continuousSpeech();
              setContentDelivered(true);
              setisTalking(false);
            }, 3000);
          }
        }

        if (event.error === "not-allowed" ) {
          backendCall = true;
          window.KlleonChat.echo("Continuing with the lecture");
          addMessage("Continuing with the lecture", 2);
          setTimeout(() => {
            sethidePause(false);
            console.log("Resuming lesson");
            setIsPaused(false);
            setIsListeningCont(true);
            continuousSpeech();
            setContentDelivered(true);
            setisTalking(false);
          }, 6000);
        }
        
        
        
      };

      recognition.onspeechend = () => {
        console.log("Speech has stopped being detected.");
      };

      recognition.onend = () => {

        
          setIsListening(false);
        console.log("Speech recognition ended.");
        if (!backendCall && counter < 3) {
          window.KlleonChat.echo("I could not understand, please speak again");
          addMessage("I could not understand, please speak again", 2);
          counter = counter + 1;
          setTimeout(() => {
            recognition.start();
          }, 6000);
        }
        
        
      };

      
        recognition.start();
    
      
  };

  
  const handleRecapLesson = async () => {
    sethidePause(true);
    setshowStart(false);
    let completeRecap;
    const requestPayload = {
      session_lesson_id: session_lesson_id,
    };
    
    try {
      const response = await fetch(`${BASE_URL}get-lesson-recap/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify(requestPayload),
      });
  
      if (response.ok) {
        const data = await response.json();
        
        try {
          const AIresponse = await fetch(`${AI_URL}get_lesson_recap`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(data.result),
          });
  
          if (AIresponse.ok) {
            setloadRecap(true);
            const reader = AIresponse.body.getReader();
            const decoder = new TextDecoder("utf-8"); 
            let streamedData = [];
  
            const processStream = async () => {
              while (true) {
                const { done, value } = await reader.read();
                if (done) {
                  break;
                }
  

                const chunk = decoder.decode(value, { stream: true });
                streamedData.push(chunk);
              }
  

              completeRecap = streamedData.join("");
              console.log("Complete streamed data:", completeRecap);
            };
  
            await processStream();
            setloadRecap(false);
            let eventListener = true;
            window.KlleonChat.echo(completeRecap);
            addMessage(completeRecap, 2);
  
            window.KlleonChat.onChatEvent((eventData) => {
              const sdkResponse = JSON.parse(eventData);
              if (sdkResponse.chat_type === TERMINAL_MESSAGE && eventListener === true) {
                eventListener = false;
                setTimeout(() => {
                  executeLesson();
                  sethidePause(false);
                }, 3000);
              }
            });
  
          } else {
            console.error("Failed to fetch the AI API response");
          }
        } catch (error) {
          console.error("Error while making API call:", error);
        }
      } else {
        console.error("Failed to fetch the recap API response");
      }
    } catch (error) {
      console.error("Error while making the recap API call:", error);
    }
  };
    const handleRecapSession = async () =>
      {
        sethidePause(true);
        setshowStart(false);
        let completeRecap;
        const requestPayload = {
       
          session_id: session_id,
        };
        try {
          const response = await fetch(`${BASE_URL}get-session-recap/`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + token,
            },
            body: JSON.stringify(requestPayload),
          });
      
          if (response.ok) {
            const data = await response.json();
            try {
              const AIresponse = await fetch(`${AI_URL}get_lesson_recap`, {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify(data.result),
              });
          
              if (AIresponse.ok) {
                setloadRecap(true);
                const reader = AIresponse.body.getReader();
                const decoder = new TextDecoder("utf-8"); 
                let streamedData = [];
      
                const processStream = async () => {
                  while (true) {
                    const { done, value } = await reader.read();
                    if (done) {
                      break;
                    }
                    const chunk = decoder.decode(value, { stream: true });
                    streamedData.push(chunk);
                  }
                  completeRecap = streamedData.join("");
                  console.log("Complete streamed data:", completeRecap);
                };
      
                await processStream();
                setloadRecap(false);
            let eventListener = true;
            window.KlleonChat.echo(completeRecap);
            addMessage(completeRecap, 2);
            window.KlleonChat.onChatEvent((eventData) => {
              const sdkResponse = JSON.parse(eventData);
              if (sdkResponse.chat_type === TERMINAL_MESSAGE && eventListener === true) {
                eventListener = false;
                setTimeout(() => {
                  executeLesson();
                  sethidePause(false);
                }, 3000);
                
              }
            });
      
              } else {
                console.error("Failed to fetch the API response");
              }
            } catch (error) {
              console.error("Error while making API call:", error);
            }
  
          } else {
            console.error("Failed to fetch the API response");
          }
        } catch (error) {
          console.error("Error while making API call:", error);
        }
    
      }
  
  return (
    <div style={{ position: "relative", height: "84vh" }}>
      <div id="klleon_chat"></div>
      {showkeyboard && !paused && (
        <div className="absolute top-6 right-6 flex justify-center">
          <button disabled={isTalking} hidden={hidePause} onClick={pauseLesson}>
            <img src="/pause-button.svg" alt="" />
          </button>
        </div>
      )}
      {showkeyboard && paused && (
        <div className="absolute top-6 right-6 flex justify-center">
          <button disabled={isTalking} onClick={resumeLesson}>
            <img src="/play-button.svg" alt="" />
          </button>
        </div>
      )}
      {showStart && progress === 0 && (
        <div className="absolute inset-0 bg-black bg-opacity-50 backdrop-blur-sm flex items-center justify-center z-50 rounded-3xl">
          <div className="text-center">
            <p className="text-white text-2xl mb-4 font-bold">
              Ready to dive in?
            </p>
            <button
              className="bg-[#34D795] w-[140px] h-[48px] rounded-[6px] text-white font-bold px-4"
              onClick={executeLesson}
            >
              Begin Lesson
            </button>
          </div>
        </div>
      )}

      {/* {paused &&  (
        <div className="absolute inset-0 bg-black bg-opacity-50 backdrop-blur-sm flex items-center justify-center z-50 rounded-3xl">
          <div className="text-center">
            <p className="text-white text-2xl mb-4 font-bold">
              Ready to continue?
            </p>
            <button
              className="bg-[#34D795] w-[140px] h-[48px] rounded-[6px] text-white font-bold px-4"
              onClick={resumeLesson}
            >
              Reume Lesson
            </button>
          </div>
        </div>
      )} */}

      {loadRecap && (
        <div className="absolute inset-0 bg-black bg-opacity-50 backdrop-blur-sm flex items-center justify-center z-50 rounded-3xl">
          <div className="text-center bg-white rounded-3xl  w-[300px] py-8 items-center flex flex-col justify-center gap-2">
            <p className="text-[#333] text-2xl mb-4 font-bold">
              Loading your recap!
            </p>
            <div className="w-[40%] h-[3px] bg-[#34D795] self-center"></div>
            <br />
            <TailSpin></TailSpin>
          </div>
        </div>
      )}
      {showStart && progress > 0 && progress < 100 && (
        <div className="absolute inset-0 bg-black bg-opacity-50 backdrop-blur-sm flex items-center justify-center z-50 rounded-3xl">
          <div className="text-center bg-white rounded-3xl px-4 py-8 w-[55%] flex flex-col justify-center items-center">
            <p className="text-[#333] text-2xl mb-4 font-bold">
              Hey, Great to See You Again!
            </p>
            <div className="w-[40%] h-[3px] bg-[#34D795] self-center"></div>
            <br />
            <p className="text-[#727674] text-sm mb-4 font-bold">
              Need a quick refresher on what we covered last time?
            </p>
            <div>
              <button
                className="bg-white w-[140px] h-[48px] rounded-[6px] text-[#34D795] text-sm px-4 mr-4 border-[2px] font-semibold border-[#34D795]"
                onClick={executeLesson}
              >
                Continue
              </button>
              <button
                className="bg-[#34D795] min-w-[140px] h-[48px] rounded-[6px] text-white text-sm px-4 mr-4 font-semi-bold"
                onClick={handleRecapLesson}
              >
                Watch Lesson Recap
              </button>
              <button
                className="bg-[#34D795] min-w-[140px] h-[48px] rounded-[6px] p-4 text-white text-sm px-4 font-semi-bold"
                onClick={handleRecapSession}
              >
                Watch Session Recap
              </button>
            </div>
          </div>
        </div>
      )}

      {showStart && progress === 100 && (
        <div className="absolute inset-0 bg-black bg-opacity-50 backdrop-blur-sm flex items-center justify-center z-50 rounded-3xl">
          <div className="text-center bg-white rounded-3xl px-4 py-8 w-[35%] items-center flex flex-col justify-center">
            <p className="text-[#333] text-2xl mb-4 font-bold">
              Hey, Great to See You Again!
            </p>
            <div className="w-[40%] h-[3px] bg-[#34D795] self-center "></div>
            <br />
            <p className="text-[#727674] text-sm mb-4 font-bold">
              It looks like you have completed this lesson. Do you want to
              retake or go to the next lesson?
            </p>
            <div>
              <button
                className="bg-white w-[140px] h-[48px] rounded-[6px] text-[#34D795] text-sm px-4 mr-4 border-[2px] font-semibold border-[#34D795]"
                onClick={handleRetake}
              >
                Retake
              </button>
              <button
                className="bg-[#34D795] w-[140px] h-[48px] rounded-[6px] text-white text-sm px-4 font-semi-bold"
                onClick={handleLessonChange}
              >
                Next Lesson
              </button>
            </div>
          </div>
        </div>
      )}

      {Modal && (
        <div className="absolute inset-0 bg-black bg-opacity-50 backdrop-blur-sm flex items-center justify-center z-50 rounded-3xl">
          <div className="text-center bg-white rounded-3xl px-4 py-8 w-[40%] items-center flex flex-col justify-center">
            <p className="text-[#333] text-2xl mb-4 font-bold">
              Are you sure you want to leave?
            </p>
            <div className="w-[40%] h-[3px] bg-[#34D795] self-center"></div>
            <br />
            <p className="text-[#727674] text-sm mb-4 font-bold">
              You are about to leave this page, leaving now will reset your
              lecture progress.
            </p>
            <div>
              <button
                className=" bg-white w-[180px] h-[48px] rounded-[6px] text-[#727674] text-sm px-4 mr-4 border-[2px] font-semibold border-[#727674]"
                onClick={closeModal}
              >
                No, Stay here
              </button>
              <button
                className=" bg-[#F23030] w-[180px] h-[48px] rounded-[6px] text-white text-sm px-4 font-semi-bold"
                onClick={navigateTo}
              >
                Yes, Leave page
              </button>
            </div>
          </div>
        </div>
      )}

      {showFC(flashCardContent)}
      <div
        ref={messageContainerRef}
        className="absolute bottom-[70px] w-[40%] max-h-[50%] right-[10px] container flex flex-col p-4 h-3/5 gap-4 overflow-y-auto hide-scroll"
      >
        {messages.length !== 0 &&
          messages.map((message, index) => (
            <div
              key={index}
              className={`flex justify-center p-4 rounded-[10px] transition duration-500 ease-in-out self-end ${
                message.type === 1 || message.type === 3
                  ? "bg-[#34D795] text-white ml-4"
                  : "bg-white text-[#333] mr-4"
              }`}
              style={{
                boxShadow:
                  "0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -2px rgba(0, 0, 0, 0.1)",
                minHeight: "auto",
              }}
            >
              <p className="w-full text-[14px] font-open_sans">
                {message.type === 1 || message.type === 2 ? (
                  <TypeAnimation
                    splitter={(str) => str.split(/(?= )/)}
                    sequence={[
                      message.text,
                      () => {
                        handleWordTyped();
                      },
                    ]}
                    speed={{ type: "keyStrokeDelayInMs", value: 360 }}
                    omitDeletionAnimation={true}
                    cursor={false}
                    style={{
                      fontSize: "1em",
                      display: "block",
                      overflow: "hidden",
                    }}
                  />
                ) : (
                  message.text
                )}
              </p>
            </div>
          ))}
      </div>

      {isListening && 
      <Lottie
      animationData={animationData}
      className="absolute bottom-0 left-[40%] flex justify-center w-[200px] h-[200px]"
    />
      }
      

      {showkeyboard && (
        <div className="absolute bottom-4 right-6 flex justify-center w-[38%]">
          <div className="w-[90%] mr-2">
            <input
              className="h-[38px] w-full rounded-[6px] p-4 text-[14px] font-open_sans border-none"
              type="text"
              value={inputValue}
              readOnly={hidePause}
              onChange={(e) => setInputValue(e.target.value)}
              placeholder={
                isListening ? "Listening...." : "Type your message here..."
              }
              onKeyDown={(e) => {
                if (e.key === "Enter" && !hidePause && inputValue !== "") {
                  handleUserInput();
                }
              }}
            />
          </div>

          {inputValue === "" ? (
            <button
              className="bg-white p-2 rounded-[6px] cursor-pointer"
              onClick={handleSpeech}
              disabled={hidePause}
            >
              <img src="/mic.svg" alt="" />
            </button>
          ) : (
            <button
              className="bg-white p-2 rounded-[6px] cursor-pointer"
              onClick={handleUserInput}
              disabled={hidePause}
            >
              <img src="/send-2.svg" alt="" />
            </button>
          )}
        </div>
      )}
    </div>
  );
};

export default AvatarScreen;
