import { GENDERS_FAIL, GENDERS_PENDING, GENDERS_SUCCESS, SET_MESSAGE, ROLES_FAIL, ROLES_PENDING, ROLES_SUCCESS, LESSON_CONTENT_FAIL, LESSON_CONTENT_SUCCESS, LESSON_CONTENT_PENDING, TEACHERS_FAIL, TEACHERS_PENDING, TEACHERS_SUCCESS, COURSES_PENDING, COURSES_SUCCESS, COURSES_FAIL, COURSE_ENROLLMENT_PENDING, COURSE_ENROLLMENT_SUCCESS, COURSE_ENROLLMENT_FAIL, COURSE_DETAIL_PENDING, COURSE_DETAIL_SUCCESS, COURSE_DETAIL_FAIL, RESET_ENROLL_COURSE, UPDATE_PASSWORD_SUCCESS, UPDATE_PASSWORD_FAIL, UPDATE_PASSWORD_PENDING, FORGOT_PASSWORD_SUCCESS, FORGOT_PASSWORD_FAIL, FORGOT_PASSWORD_PENDING, RESET_FORGOT_PASSWORD, RESET_UPDATE_PASSWORD, USER_COURSES_PENDING, USER_COURSES_SUCCESS, USER_COURSES_FAIL, USER_COURSE_DETAIL_PENDING, USER_COURSE_DETAIL_SUCCESS, USER_COURSE_DETAIL_FAIL, RESET_CREATE_SESSION, CREATE_SESSION_FAIL, CREATE_SESSION_PENDING, CREATE_SESSION_SUCCESS, DASHBOARD_DATA_PENDING, DASHBOARD_DATA_SUCCESS, DASHBOARD_DATA_FAIL, PROFILE_FAIL, PROFILE_PENDING, PROFILE_SUCCESS, SEND_VERIFICATION_EMAIL_FAIL, SEND_VERIFICATION_EMAIL_PENDING, SEND_VERIFICATION_EMAIL_SUCCESS, ADMIN_DASHBOARD_DATA_FAIL, ADMIN_DASHBOARD_DATA_PENDING, ADMIN_DASHBOARD_DATA_SUCCESS, ALL_STUDENTS_FAIL, ALL_STUDENTS_SUCCESS, ALL_STUDENTS_PENDING, STUDENT_DETAIL_FAIL, STUDENT_DETAIL_PENDING, STUDENT_DETAIL_SUCCESS } from "./Types"
import UserServices from "../../Services/UserServices"
import { toast } from "react-toastify"




export const getGenders = () => async (dispatch) => {
    dispatch({
        type: GENDERS_PENDING,
        payload: { getGenders: "isLoading" },
    });
    return await UserServices.getGenders()
        .then(
            (data) => {
                if (data.data.status === true) {
                    dispatch({
                        type: GENDERS_SUCCESS,
                        payload: { getGenders: data.data },
                    })
                }
                else {
                    dispatch({
                        type: GENDERS_FAIL,
                    });
                    toast.error(data.data.message, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
                return Promise.resolve();
            },
            (error) => {
                console.log("error ", error);
                const message =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();
                dispatch({
                    type: GENDERS_FAIL,
                });
                dispatch({
                    type: SET_MESSAGE,
                    payload: message,
                });
                return Promise.reject();
            }
        )
        .catch((error) => {
            console.log(error);
        });
};


export const getRoles = () => async (dispatch) => {
    dispatch({
        type: ROLES_PENDING,
        payload: { getRoles: "isLoading" },
    });
    return await UserServices.getRoles()
        .then(
            (data) => {
                if (data.data.status === true) {
                    dispatch({
                        type: ROLES_SUCCESS,
                        payload: { getRoles: data.data },
                    })
                }
                else {
                    dispatch({
                        type: ROLES_FAIL,
                    });
                    toast.error(data.message, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
                return Promise.resolve();
            },
            (error) => {
                console.log("error ", error);
                const message =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();
                dispatch({
                    type: ROLES_FAIL,
                });
                dispatch({
                    type: SET_MESSAGE,
                    payload: message,
                });
                return Promise.reject();
            }
        )
        .catch((error) => {
            console.log(error);
        });
};

export const getLessonsContent = (body) => async (dispatch) => {
    dispatch({
        type: LESSON_CONTENT_PENDING,
        payload: { getLessonsContent: "isLoading" },
    });
    return await UserServices.getLessonsContent(body)
        .then(
            (data) => {
                if (data.data.status === true) {
                    dispatch({
                        type: LESSON_CONTENT_SUCCESS,
                        payload: { getLessonsContent: data.data },
                    })
                }
                else {
                    dispatch({
                        type: LESSON_CONTENT_FAIL,
                    });
                    toast.error(data.message, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
                return Promise.resolve();
            },
            (error) => {
                console.log("error ", error);
                dispatch({
                    type: LESSON_CONTENT_FAIL,
                });
                return Promise.reject();
            }
        )
        .catch((error) => {
            console.log(error);
        });
};

export const getCourses = () => async (dispatch) => {
    dispatch({
        type: COURSES_PENDING,
        payload: { getCourses: "isLoading" },
    });
    return await UserServices.getCourses()
        .then(
            (data) => {
                if (data.data.status === true) {
                    dispatch({
                        type: COURSES_SUCCESS,
                        payload: { getCourses: data.data },
                    })
                }
                else {
                    dispatch({
                        type: COURSES_FAIL,
                    });
                    toast.error(data.message, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
                return Promise.resolve();
            },
            (error) => {
                console.log("error ", error);
                dispatch({
                    type: COURSES_FAIL,
                });
                return Promise.reject();
            }
        )
        .catch((error) => {
            console.log(error);
        });
};

export const getTeachers = () => async (dispatch) => {
    dispatch({
        type: TEACHERS_PENDING,
        payload: { getTeachers: "isLoading" },
    });
    return await UserServices.getTeachers()
        .then(
            (data) => {
                if (data.data.status === true) {
                    dispatch({
                        type: TEACHERS_SUCCESS,
                        payload: { getTeachers: data.data },
                    })
                }
                else {
                    dispatch({
                        type: TEACHERS_FAIL,
                    });
                    toast.error(data.message, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
                return Promise.resolve();
            },
            (error) => {
                console.log("error ", error);
                dispatch({
                    type: TEACHERS_FAIL,
                });
                return Promise.reject();
            }
        )
        .catch((error) => {
            console.log(error);
        });
};

export const enrollCourse = (body) => async (dispatch) => {
    dispatch({
        type: COURSE_ENROLLMENT_PENDING,
        payload: { courseEnrollment: "isLoading" },
    });
    return await UserServices.enrollCourse(body)
        .then(
            (data) => {
                if (data.data.status === true) {
                    dispatch({
                        type: COURSE_ENROLLMENT_SUCCESS,
                        payload: { courseEnrollment: data.data },
                    })
                }
                else {
                    dispatch({
                        type: COURSE_ENROLLMENT_FAIL,
                    });
                    toast.error(data.data.message, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
                return Promise.resolve();
            },
            (error) => {
                console.log("error ", error);
                dispatch({
                    type: COURSE_ENROLLMENT_FAIL,
                });
                return Promise.reject();
            }
        )
        .catch((error) => {
            console.log(error);
        });
};
export const resetEnrollCourse = () => ({
    type: RESET_ENROLL_COURSE,
});

export const getCourseDetail = (id) => async (dispatch) => {
    dispatch({
        type: COURSE_DETAIL_PENDING,
        payload: { getCourseDetail: "isLoading" },
    });
    return await UserServices.getCourseDetail(id)
        .then(
            (data) => {
                if (data.data.status === true) {
                    dispatch({
                        type: COURSE_DETAIL_SUCCESS,
                        payload: { getCourseDetail: data.data },
                    })
                }
                else {
                    dispatch({
                        type: COURSE_DETAIL_FAIL,
                    });
                    toast.error(data.message, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
                return Promise.resolve();
            },
            (error) => {
                console.log("error ", error);
                dispatch({
                    type: COURSE_DETAIL_FAIL,
                });
                return Promise.reject();
            }
        )
        .catch((error) => {
            console.log(error);
        });
};

export const updatePassword = (body) => async (dispatch) => {
    dispatch({
        type: UPDATE_PASSWORD_PENDING,
        payload: { updatePassword: "isLoading" },
    });
    return await UserServices.updatePassword(body)
        .then(
            (data) => {
                if (data.data.status === true) {
                    dispatch({
                        type: UPDATE_PASSWORD_SUCCESS,
                        payload: { updatePassword: data.data },
                    })
                }
                else {
                    dispatch({
                        type: UPDATE_PASSWORD_FAIL,
                    });
                    toast.error(data.data.message, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
                return Promise.resolve();
            },
            (error) => {
                console.log("error ", error);
                dispatch({
                    type: UPDATE_PASSWORD_FAIL,
                });
                return Promise.reject();
            }
        )
        .catch((error) => {
            console.log(error);
        });
};
export const resetUpdatePassword = () => ({
    type: RESET_UPDATE_PASSWORD,
});

export const forgotPassword = (body) => async (dispatch) => {
    dispatch({
        type: FORGOT_PASSWORD_PENDING,
        payload: { forgotPassword: "isLoading" },
    });
    return await UserServices.forgotPassword(body)
        .then(
            (data) => {
                if (data.data.status === true) {
                    dispatch({
                        type: FORGOT_PASSWORD_SUCCESS,
                        payload: { forgotPassword: data.data },
                    })
                }
                else {
                    dispatch({
                        type: FORGOT_PASSWORD_FAIL,
                    });
                    toast.error(data.data.message, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
                return Promise.resolve();
            },
            (error) => {
                console.log("error ", error);
                dispatch({
                    type: FORGOT_PASSWORD_FAIL,
                });
                return Promise.reject();
            }
        )
        .catch((error) => {
            console.log(error);
        });
};
export const resetForgotPassword = () => ({
    type: RESET_FORGOT_PASSWORD,
});


export const getUserCourses = (body) => async (dispatch) => {
    dispatch({
        type: USER_COURSES_PENDING,
        payload: { getUserCourses: "isLoading" },
    });
    return await UserServices.getUserCourses(body)
        .then(
            (data) => {
                if (data.data.status === true) {
                    dispatch({
                        type: USER_COURSES_SUCCESS,
                        payload: { getUserCourses: data.data },
                    })
                }
                else {
                    dispatch({
                        type: USER_COURSES_FAIL,
                    });
                    toast.error(data.data.message, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
                return Promise.resolve();
            },
            (error) => {
                console.log("error ", error);
                dispatch({
                    type: USER_COURSES_FAIL,
                });
                return Promise.reject();
            }
        )
        .catch((error) => {
            console.log(error);
        });
};

export const getUserCourseDetail = (body) => async (dispatch) => {
    dispatch({
        type: USER_COURSE_DETAIL_PENDING,
        payload: { getUserCourseDetail: "isLoading" },
    });
    return await UserServices.getUserCourseDetail(body)
        .then(
            (data) => {
                if (data.data.status === true) {
                    dispatch({
                        type: USER_COURSE_DETAIL_SUCCESS,
                        payload: { getUserCourseDetail: data.data },
                    })
                }
                else {
                    dispatch({
                        type: USER_COURSE_DETAIL_FAIL,
                    });
                    toast.error(data.data.message, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
                return Promise.resolve();
            },
            (error) => {
                console.log("error ", error);
                dispatch({
                    type: USER_COURSE_DETAIL_FAIL,
                });
                return Promise.reject();
            }
        )
        .catch((error) => {
            console.log(error);
        });
};

export const createSession = (body) => async (dispatch) => {
    dispatch({
        type: CREATE_SESSION_PENDING,
        payload: { createSession: "isLoading" },
    });
    return await UserServices.createSession(body)
        .then(
            (data) => {
                if (data.data.status === true) {
                    dispatch({
                        type: CREATE_SESSION_SUCCESS,
                        payload: { createSession: data.data },
                    })
                }
                else {
                    dispatch({
                        type: CREATE_SESSION_FAIL,
                    });
                    toast.error(data.data.message, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
                return Promise.resolve();
            },
            (error) => {
                console.log("error ", error);
                dispatch({
                    type: CREATE_SESSION_FAIL,
                });
                return Promise.reject();
            }
        )
        .catch((error) => {
            console.log(error);
        });
};
export const resetCreateSession = () => ({
    type: RESET_CREATE_SESSION,
});


export const getDashboardData = (body) => async (dispatch) => {
    dispatch({
        type: DASHBOARD_DATA_PENDING,
        payload: { getDashboardData: "isLoading" },
    });
    return await UserServices.getDashboardData(body)
        .then(
            (data) => {
                if (data.data.status === true) {
                    dispatch({
                        type: DASHBOARD_DATA_SUCCESS,
                        payload: { getDashboardData: data.data },
                    })
                }
                else {
                    dispatch({
                        type: DASHBOARD_DATA_FAIL,
                    });
                    toast.error(data.data.message, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
                return Promise.resolve();
            },
            (error) => {
                console.log("error ", error);
                dispatch({
                    type: DASHBOARD_DATA_FAIL,
                });
                return Promise.reject();
            }
        )
        .catch((error) => {
            console.log(error);
        });
};

export const getProfile = () => async (dispatch) => {
    dispatch({
        type: PROFILE_PENDING,
        payload: { getDashboardData: "isLoading" },
    });
    return await UserServices.getProfile()
        .then(
            (data) => {
                if (data.data.status === true) {
                    dispatch({
                        type: PROFILE_SUCCESS,
                        payload: { getProfile: data.data },
                    })
                }
                else {
                    dispatch({
                        type: PROFILE_FAIL,
                    });
                    toast.error(data.data.message, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
                return Promise.resolve();
            },
            (error) => {
                console.log("error ", error);
                dispatch({
                    type: PROFILE_FAIL,
                });
                return Promise.reject();
            }
        )
        .catch((error) => {
            console.log(error);
        });
};

export const sendVerificationEmail = (body) => async (dispatch) => {
    dispatch({
        type: SEND_VERIFICATION_EMAIL_PENDING,
        payload: { sendVerificationEmail: "isLoading" },
    });
    return await UserServices.sendVerificationEmail(body)
        .then(
            (data) => {
                if (data.data.status === true) {
                    dispatch({
                        type: SEND_VERIFICATION_EMAIL_SUCCESS,
                        payload: { sendVerificationEmail: data.data },
                    })
                    toast.success(data.data.message, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
                else {
                    dispatch({
                        type: SEND_VERIFICATION_EMAIL_FAIL,
                    });
                    toast.error(data.data.message, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
                return Promise.resolve();
            },
            (error) => {
                console.log("error ", error);
                dispatch({
                    type: SEND_VERIFICATION_EMAIL_FAIL,
                });
                return Promise.reject();
            }
        )
        .catch((error) => {
            console.log(error);
        });
};

export const getAdminDashboardData = () => async (dispatch) => {
    dispatch({
        type: ADMIN_DASHBOARD_DATA_PENDING,
        payload: { getDashboardData: "isLoading" },
    });
    return await UserServices.getAdminDashboardData()
        .then(
            (data) => {
                if (data.data.status === true) {
                    dispatch({
                        type: ADMIN_DASHBOARD_DATA_SUCCESS,
                        payload: { getDashboardData: data.data },
                    })
                }
                else {
                    dispatch({
                        type: ADMIN_DASHBOARD_DATA_FAIL,
                    });
                    toast.error(data.data.message, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
                return Promise.resolve();
            },
            (error) => {
                console.log("error ", error);
                dispatch({
                    type: ADMIN_DASHBOARD_DATA_FAIL,
                });
                return Promise.reject();
            }
        )
        .catch((error) => {
            console.log(error);
        });
};

export const getAllStudents = (body) => async (dispatch) => {
    dispatch({
        type: ALL_STUDENTS_PENDING,
        payload: { getAllStudents: "isLoading" },
    });
    return await UserServices.getAllStudents(body)
        .then(
            (data) => {
                if (data.data.status === true) {
                    dispatch({
                        type: ALL_STUDENTS_SUCCESS,
                        payload: { getAllStudents: data.data },
                    })
                }
                else {
                    dispatch({
                        type: ALL_STUDENTS_FAIL,
                    });
                    toast.error(data.data.message, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
                return Promise.resolve();
            },
            (error) => {
                console.log("error ", error);
                dispatch({
                    type: ALL_STUDENTS_FAIL,
                });
                return Promise.reject();
            }
        )
        .catch((error) => {
            console.log(error);
        });
};

export const getStudentDetail = (body) => async (dispatch) => {
    dispatch({
        type: STUDENT_DETAIL_PENDING,
        payload: { getStudentDetail: "isLoading" },
    });
    return await UserServices.getStudentDetail(body)
        .then(
            (data) => {
                if (data.data.status === true) {
                    dispatch({
                        type: STUDENT_DETAIL_SUCCESS,
                        payload: { getStudentDetail: data.data },
                    })
                }
                else {
                    dispatch({
                        type: STUDENT_DETAIL_FAIL,
                    });
                    toast.error(data.data.message, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
                return Promise.resolve();
            },
            (error) => {
                console.log("error ", error);
                dispatch({
                    type: STUDENT_DETAIL_FAIL,
                });
                return Promise.reject();
            }
        )
        .catch((error) => {
            console.log(error);
        });
};