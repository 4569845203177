import React, { useState, useRef, useEffect } from 'react';
import { StudentSessionCard } from './StudentSessionCard';

const StudentAccordion = ({ courseData }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [maxHeight, setMaxHeight] = useState('0px');
    const contentRef = useRef(null);

    useEffect(() => {
        if (isOpen) {
            setMaxHeight(`${contentRef.current.scrollHeight}px`);
        } else {
            setMaxHeight('0px');
        }
    }, [isOpen]);

    return (
        <div className="w-full rounded-[14px] p-[16px] gap-[16px] border-b border-neutral-5 shadow-sessioncard lg:p-[24px] lg:gap-[24px] overflow-hidden">
            <button
                onClick={() => setIsOpen(!isOpen)}
                className="flex items-center justify-between w-full button3 bg-white text-font_color-primary lg:button1 "
            >
                <p className='text-left Heading-18 '>
                    {courseData.title}
                </p>

                <span className="flex-shrink-0 flex items-center justify-center border border-neutral-4 shadow-sessioncard rounded-[88px] w-[32px] h-[32px] lg:p-[12px] lg:w-[40px] lg:h-[40px]">
                    <svg
                        className={`transform transition-transform duration-300 ${isOpen ? 'rotate-180' : 'rotate-0'}`}
                        width="16" height="10" viewBox="0 0 16 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1.48096 1.75488L7.99995 8.24442L14.519 1.75488" stroke="#8C8C8C" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                </span>
            </button>
            <div
                ref={contentRef}
                className="transition-[max-height,opacity] duration-300 ease-out overflow-hidden"
                style={{ maxHeight, opacity: isOpen ? 1 : 0 }}
            >
                <div className="flex flex-col bg-white text-font_color-primary text-left">
                    <p className='text-left py-[4px] subtitle1'>Sessions</p>
                    <div className='flex flex-wrap h-full gap-[16px] lg:gap-[24px] lg:max-h-[309px] lg:overflow-y-auto'>
                        {courseData.sessions.map((session) => (
                            <StudentSessionCard
                            sessionData={session}
                            />
                        ))}


                    </div>

                </div>
            </div>
        </div>
    );
};


export default StudentAccordion;