import React, { useEffect } from "react";
import StudentAccordion from "./StudentAccordion";
import { useDispatch, useSelector } from "react-redux";
import { getStudentDetail } from "../../Redux/Actions/UserActions";
import { useLocation } from 'react-router-dom';
import { TailSpin } from 'react-loader-spinner';


export const StudentDetail = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const { studentId } = location.state || {};
    const studentDetail = useSelector((state) => state.student_detail_reducer.getStudentDetail);

    useEffect(() => {
        const body = {
            "user_id": studentId
        }
        dispatch(getStudentDetail(body));
    }, [])
    return (
        <div className="flex flex-col mx-[10px] gap-[16px] lg:gap-[24px]">
            {studentDetail && studentDetail === "isLoading" && (
                <div className='flex items-center justify-center h-full'>
                    <TailSpin color="#34D795" height={50} width={50} />
                </div>
            )}
            {studentDetail && studentDetail !== "isLoading" && studentDetail.length !== 0 && studentDetail.result && studentDetail.result.length !== 0 && (
                <>
                    <div className="flex flex-col gap-[16px] lg:flex-row lg:justify-between">
                        <div className="flex flex-col gap-[8px]">
                            <p className="text-left text-font_color-primary Heading-20 lg:Heading-32">{studentDetail.result.user.first_name + " " + studentDetail.result.user.last_name}</p>
                            <p className="text-left text-font_color-body body-2 lg:body-00">{studentDetail.result.user.email}</p>
                        </div>
                        <div className="flex items-center py-[8px] px-[12px] gap-[10px]">
                            <p className="text-left text-font_color-body subtitle2 lg:subtitle1">Overall score: <span className="text-left text-font_color-primary ">{studentDetail.result.user.achieved_score} out of {studentDetail.result.user.overall_score} </span></p>
                            <div className="flex-grow bg-neutral-4 rounded-full h-[9px] w-[90px]">
                                <div className="bg-primary-1 h-[9px] rounded-full" style={{ width: `${studentDetail.result.user.percentage}%` }}></div>
                            </div>
                        </div>
                    </div>
                    {studentDetail.result.courses && studentDetail.result.courses.length !== 0 && studentDetail.result.courses.map((course) => (
                        <StudentAccordion
                            courseData={course}
                        />
                    ))}
                    {studentDetail.result.courses && studentDetail.result.courses.length === 0 && (
                        <p className="text-left body-1">Currently student is not enrolled in any course</p>
                    )}

                </>
            )}


        </div>
    )
}